.tag-inversion-pagada,
.tag-inversion-ejecutada,
.tag-inversion-cancelada,
.tag-inversion-pendiente{
	background: #FFE608 !important;
	border: none !important;
	min-width: 100px;
	text-align: center;
	color: rgba(255, 255, 255, 0.7) !important;
	letter-spacing: 0.1em;
	font-size: 15px;
}

.tag-inversion-pendiente{
	background: #FFE608 !important;
}

.tag-inversion-pagada{
	background: #2DDA93 !important;
}

.tag-inversion-ejecutada{
	background: #16B1C2 !important;
}

.tag-inversion-cancelada{
	background: #FF547D !important;
}