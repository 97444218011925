// Pallete
$accent: #E92011;
$primary: #EE492A;
$secondary: #323232;
$text: #323232;
$text-2: #646464;

// Screens
$desktop: 1400px;
$laptop: 1200px;
$tablet: 900px;
$phone: 600px;
$sm-phone: 400px;

.header-public {
	height: unset !important;
	display: flex;
    align-items: center;
    width: 100%;
    z-index: 1000;
    position: sticky;
    top: 0;
    background: white;
    font-size: 16px;
	font-weight: 500;
 	font-family: "Ubuntu";
    transition: box-shadow 0.5s ease-in-out;
    padding: 1rem 0;

 	&.scrolled {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    .main-header-container{
    	width: 100%;
    	max-width: 1300px;
    	margin: 0 auto !important;
    }

    .brand{
    	width: 225px;
    }

    .link{
    	min-width: 50px;
    	display: inline-block;
    	font-size: 16px;
		font-weight: 500;
	 	font-family: "Ubuntu";
		color: #646464;
		margin: 0 1rem;
		text-align: center;
    }

    .action-button{
    	font-size: 14px;
		font-weight: 500;
    	background-color: $primary;
	    height: 56px;
	    border: none;
	    border-radius: 0px;
	    color: white;
	    padding: 0 1.5rem;
    }
}


/* .header-public .ant-menu {
	color: #fff;
	font-weight: 300;
	background: inherit;
	display: flex;
	align-items: center;
	align-content: stretch;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.header-public .ant-menu-item:hover {
	background: transparent;
	color: var(--cyan);
}

.header-public .ant-menu-item-selected {
	background: transparent;
	font-weight: bold;
} */






@media(max-width: 881px) {


	.div-container {
		width: 100%;
		flex-direction: row;
		display: flex;
		align-content: center;
		justify-content: center;
		line-height: 64px;
	}

	.space-menu {
		display: flex !important;
		flex-direction: column;
		align-items: flex-start !important;
		justify-content: flex-start;
	}

	.menu-item-mobile,
	.header-public-menu-mobile {
		display: block !important;
		visibility: visible !important;
	}

	.menu-item-desktop,
	.header-public-desktop,
	.header-public-menu-desktop {
		display: none !important;
		visibility: hidden !important;
	}

}

@media(min-width: 881px) {

	.menu-item-desktop,
	.header-public-desktop,
	.header-public-menu-desktop {
		display: block !important;
		visibility: visible !important;
	}

	.div-container {
		width: 100%;
		flex-direction: row;
		display: flex;
		align-content: center;
		justify-content: center;
		line-height: 64px;
	}

}

@media(max-width: 920px) {

	.btn-green.menu {
		display: none !important;
	}

	.btn-green.out {
		margin-top: 10px;
	}

}

@media(min-width: 920px) {
	.btn-green.out {
		display: none !important;
	}


}

@media(max-width: 918px) {
	.header-public .ant-dropdown-trigger {
		display: none;
	}
}

@media(max-width: 590px) {
	.header-public {
		flex-direction: column;
		height: unset;
	}
}

.header-admin {
	padding: 0 !important;
	height: unset !important;
	background: white !important;
}

.header-admin .logo-header {
	width: 100%;
	max-width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: rgba($color: #EE492A, $alpha: 1);
}

.header-admin .logo-header img {
	width: 70%;

}

.header-admin .header-content {
	padding-top: 0.6rem;
}

.header-admin .ant-input-group-wrapper {
	max-width: 500px;
	margin-left: 1rem;
}

.notifications-list {
	width: 280px;
}

.notification-element {
	height: 70px !important;
}

.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-detail,
.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-actions {
	margin: 0px;
	padding: 0px;
	line-height: 15px;
}

@media(max-width:835px) {

	/* .header-admin .width-100{
		padding-top: 10px;
	} */
	.header-admin .logo-header {
		display: none;
	}
}