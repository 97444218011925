@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600&display=swap');


@mixin font($size, $weight: 400, $height: 0, $family: 'Ubuntu'){
  font-size: $size;
  font-weight: $weight;
  font-family: $family;
  
  @if $height != 0 {
    line-height: $height;
  }
}

// Pallete
$accent: #E92011;
$primary: #EE492A;
$secondary: #323232;
$text: #323232;
$text-2: #646464;

// Screens
$desktop: 1400px;
$laptop: 1200px;
$tablet: 900px;
$phone: 600px;
$sm-phone: 400px;

.hide{
  display: none !important;

}

@media (max-width: $desktop){
  .hide{
    &-desktop{
      display: none;
    }
  } 
}

/**
* Barra de navegacion principal, paara el area publica
*/
.main-navbar{
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    
  .wrapper{
    width: 100%;
    max-width: 1300px;
  }

  .logo{
    width: 100%;
    max-width: 225px;
  }

  .links{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    
  a.link{
    @include font(16px, 500);
    color: $text-2;
  }

  .actions{
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    &-quote,
    &-quote:hover{
      width: 210px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 12px;
      background-color: $primary !important;
      color: white;
      @include font(14px, 500);
      
      *{
        color: white;
      }
    }

    &-quote.sm {
      border-radius: 0;
      border: none;
      height: 46px;
      width: 64px !important; 
    }

    &-menu{
      display: none;
      color: white;
      font-size: 28px;
    }
  }
}

@media (max-width: $laptop){
  .main-navbar{
    .actions-menu{
      display: flex;
    }
    .links{
      display: none;
    }
  }
}

@media (max-width: $phone){
  .main-navbar{
    .actions-quote{
      display: none;
    }
  }
}
////////////////////////FIN DE Barra de navegacion principal///////////////////////////

/*************************SECCION DE NOSOTROS*********************************************/

.nosotros{
  .div-card{
    min-height: 420px;
    border-radius: 4px;
    padding: 0.5rem 3rem;

    .div-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 42px;
      margin-top: 2rem;
    }

    .card-title{
      text-align: center;
      margin: 1rem 0 !important;
    }

    .card-text{
      text-align: justify;
      font-size: 16px;
      font-weight: 400;
      font-family: "Ubuntu";
      line-height: 28px;
    }

    &.orange{
      background-color: #EE492A;
      
      .card-title,
      .card-text,
      .div-icon *{
       color: white !important;
      }

    }

    &.white{
      border: solid 1px #d8d8d8 !important;
    }

   
  }
}

/////////////////////////FIN SECCION DE NOSOTROS///////////////////////////////////////

/*************************SECCION DE CATEGORIAS DE PRODUCTOS*********************************************/

#categorias-productos{

  .content{
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
  }

  .div-card{
    min-height: 320px;
    padding: 8px;

    &:hover{
      cursor: pointer;
      border: solid 1px #a5a5a5;
    }

    .div-image{
      margin: 0 auto;
    }

    .card-title{
      color: #EE492A;
      font-weight: bold;
      text-align: center;
    }

  }

}


#landing{
  
  overflow-x: hidden;
  
  background-color: white;

  .ant-typography{
    color: $text;
  }

  .ant-row{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  #header{
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  @media (max-width: $tablet){
    #header{
      min-height: 100vh;
      height: auto;
    }
  }
  
  #navbar{
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 20;
    
    .wrapper{
      width: 100%;
      max-width: 1300px;
    }

    .logo{
      width: 100%;
      max-width: 225px;
    }

    .links{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    a.link{
      @include font(16px, 500);
      color: $text-2;

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6){
        color: white;

        *{
          color: white;
        }
      }
    }

    .center-element {
      background-color: #EE492A;
      padding: 10px;
    }

    .actions{
      display: flex;
      justify-content: flex-end;
      gap: 24px;

      &-quote{
        width: 210px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 12px;
        background-color: $primary;
        color: white;
        @include font(14px, 500);
        
        *{
          color: white;
        }
      }

      &-quote.sm {
        border-radius: 0;
        border: none;
        height: 46px;
        width: 64px !important; 
      }

      &-menu{
        display: none;
        color: white;
        font-size: 28px;
      }
    }

    
  }

  @media (max-width: $laptop){
    #navbar{
      .actions-menu{
        display: flex;
      }
      .links{
        display: none;
      }
    }
  }

  @media (max-width: $phone){
    #navbar{
      .actions-quote{
        display: none;
      }
    }
  }

  #welcome{
    display: grid;
    height: 100vh;
    margin-top: -68px;
    position: relative;
    z-index: 10;

    grid-template-columns: 50% 50%;
    grid-template-areas: 'Content Video';

    .content{
      grid-area: Content;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px;
      position: relative;
      z-index: 20;
      

      .wrapper{
        width:100%;
        max-width: 500px;
        background-color: white;
      }
      
      .subtitle{
        @include font(16px);
        color: $primary;
      }

      .title{
        @include font(40px, 700, 64px);
        margin: 12px 0 50px 0;
      }

      .description{
        display: block;
        @include font(16px);
        margin: 0 0 32px 0;
      }

      .links{
        display: flex;
        align-items: center;
        column-gap: 24px;
      }

      .link{
        padding: 16px 24px;
        @include font(16px, 500, 0, 'DM Sans');
        border-radius: 2px;
        border: 1.5px solid $accent;

        &:nth-child(1){  
          color: white;
          background-color: $accent;
        }
        &:nth-child(2){  
          color: $accent;
          background-color: white;
        }
      }
    }

    .video{
      grid-area: Video;
      position: relative;
      z-index: 10;

      video{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .button{
        position: absolute;
        z-index: 50;
        top: 50%;
        left: 50%;
        transform: scale(0.5) translateX(-50%) translateY(-50%);
        cursor: pointer;
        
        width: 108px;
        height: 108px;
        border-radius: 50%;
        border: none;
        background-color: rgba($color: #fff, $alpha: 0.3);
        display: none;
        opacity: 0;
        justify-content: center;
        align-items: center;
  
        div{
          width: 88px;
          height: 88px;
          background-color: rgba($color: #fff, $alpha: 1);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          transform: scale(1);
          transition: all 0.5s;
  
          &:hover{
            transform: scale(1.1);
            transition: all 0.5s;
          }
        }
      }
    }
  }

  @media (max-width: $laptop){
    #welcome{
      grid-template-columns: 60% 40%;
    }
  }

  @media (max-width: $tablet){
    #welcome{
      min-height: 100vh;
      height: auto;
      grid-template-columns: 1fr;
      grid-template-areas: 'Video' 'Content';

      .video{
        min-height: 450px;
        height: 60vh;
      }

      .content{
        margin-top: -10vh;
        justify-content: center;

        .wrapper{
          max-width: 80%;
          padding: 24px;
        }
      }
    }
  }

  @media (max-width: $phone){
    #welcome{

      .video{
        min-height: 450px;
        height: 60vh;
      }

      .content{

        .wrapper{
          max-width: 90%;
          padding: 24px;
        }

        .title{
          @include font(32px, 700, 50px);
        }
      }
    }
  }
 // HEADER

  #experience{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 24px;
    background-color: $secondary;

    .wrapper{
      width: 100%;
      max-width: 900px;

      *{
        color: white;
      }
      
      .data{
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .number{
        @include font(64px, 700);
        margin-bottom: 16px;
      }

      .type{
        @include font(18px, 700);
      }

      .subtype{
        @include font(16px);
      }

      .div{
        padding: 28px 0px;
        display: flex;
        justify-content: center;
      }

      .div{
        &-vertical{
          height: 100%;
          border-color: white;
        }
        &-horizontal{
          border-color: white;
          width: 100%;
          display: none;
        }
      }
    }
  } 
  @media (max-width: $tablet){
    #experience{
      .wrapper{
        .div{
          &-vertical{
            display: none;
          }
          &-horizontal{
            display: block;
          }
        }
      }
    }
  }
  // MAIN EXPERIENCE
  
  #services{
    margin-top: 200px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    justify-content: center;

    .bg{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      &-orange{
        height: 666px;
        background-color: $primary;
      }
    }

    .content{
      position: relative;
      width: 100%;
      max-width: 1200px;
      height: 666px;

      .thumb{
        width: 582px;
        height: 582px;
        object-fit: cover;
        transform: translateY(-50px);
      }

      .article{
        padding: 60px 0 0 50px;

        span, h2, div, blockquote{
          color: white;
        }

        div.ant-typography{
          margin: 0;
          @include font(16px, 400, 29px);
        }

        blockquote{
          margin: 24px 0;
          padding: 2px 0 2px 24px;
          border-left: 4px solid white;
          @include font(16px, 400, 29px);
        }
      }

      .subtitle{
        @include font(16px);
      }

      .title{
        max-width: 500px;
        margin: 5px 0 24px 0;
        @include font(40px, 700, 60px);
      }
    }
  }

   @media (max-width: $laptop){
    #services{
      .bg-orange{
        height: 750px;
      }
      .content{
        height: 800px;

        .image{
          display: flex;
          justify-content: center;
          padding: 0 16px;
        }
        .thumb{
          width: 100%;
          max-width: 400px;
          max-height: 400px;
        }
        .article{
          padding: 60px 24px 0 32px;
        }
      }
    }
  }
  @media (max-width: $tablet){
    #services{
      .bg-orange{
        height: 1000px;
      }
      .content{
        height: 1000px;
        .image{
          max-height: 400px;
        }
        .article{
          padding: 0px 24px 0 32px;
        }
      }
    }
  }

   @media (max-width: $phone){
    #services{
      .bg-orange{
        height: 1200px;
      }
      .content{
        height: 1200px;
        .title{
          @include font(32px, 700, 40px);
        }
      }
    }
  }

  @media (max-width: $sm-phone){
    #services{
      .bg-orange{
        height: 1300px;
      }
      .content{
        height: 1300px;
      }
    }
  }

  #alcance{
    display: flex;
    justify-content: center;
    padding: 80px 24px;

    .wrapper{
      width:100%;
      max-width: 1300px;

      .gallery{
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .image{
        width: 100%;
        height: 590px;
        object-fit: cover;
      }

      .header{
        width: 100%;
        max-width: 930px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .subtitle{
        text-transform: uppercase;
        color: #EE492A;
        @include font(16px);
        letter-spacing: 1px;
      }

      .title{
        max-width: 550px;
        margin: 8px 0 24px 0;
        @include font(32px, 700, 50px);
        letter-spacing: 0.5px;
      }

      .description{
        color: #646464;
        @include font(16px, 400, 28px);
      }
    }
  }

  @media (max-width: calc($tablet - 200px)){
    #alcance{
      .wrapper{
        .image{
          height: 300px;
        }

        & > .ant-col{
          padding: 0px !important;
        }
      }
    }
  }

  #tools{
    display: flex;
    justify-content: center;
    padding: 80px 24px;

    .wrapper{
      width:100%;
      max-width: 1300px;

      .header{
        width: 100%;
        max-width: 930px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .subtitle{
        text-transform: uppercase;
        color: #EE492A;
        @include font(16px);
        letter-spacing: 1px;
      }

      .title{
        max-width:550px;
        margin: 8px 0 24px 0;
        @include font(40px, 700, 60px);
      }

      .description{
        color: #646464;
        @include font(16px, 400, 28px);
      }

      .gallery{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 280px;
        grid-template-areas: 
        'Img1 Img3'
        'Img2 Img3';

        gap: 16px;

        img:nth-child(1){
          grid-area: Img1;
        }

        img:nth-child(2){
          grid-area: Img2;
        }

        img:nth-child(3){
          grid-area: Img3;
        }
      }

      .image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  @media (max-width: calc($tablet - 200px)){
    #tools{
      .wrapper{
        & > .ant-col{
          padding: 0px !important;
        }
        .gallery{
          grid-template-columns: 1fr;
          grid-template-areas: 'Img1' 'Img2' 'Img3';
        }
      }
    }
  }

  #about{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    padding: 150px 24px;

    .content{
      width: 100%;
      max-width: 1300px;
    }

    .header{
      width: 100%;
      max-width: 930px;
      display: flex;
      flex-direction: column;
      align-items: center;

      *{
        text-align: center;
      }
    }

    .subtitle{
      text-transform: uppercase;
      color: #EE492A;
      @include font(16px);
      letter-spacing: 1px;
    }

    .title{
      max-width: 440px;
      margin: 8px 0 24px 0;
      @include font(40px, 700, 60px);
    }

    .description{
      color: #646464;
      @include font(16px, 400, 28px);
    }

    .card{
      width: 100%;
      height: 260px;
      display: grid;
      grid-template-columns: 1fr 63px;
      grid-template-rows: 52px 1fr;
      grid-template-areas: "Title Icon" "Desc Desc";
      align-items: center;
      row-gap: 23px;
      column-gap: 23px;
      padding: 20px 20px;
      border: 1px solid #D8D8D8;
    }

    .card{
      &-title{
        grid-area: Title;
        @include font(24px, 700);
        margin: 0;
      }
      &-icon{
        grid-area: Icon;
        width: 100%;
        height: 100%;
      }
      &-desc{
        color: #646464;
        grid-area: Desc;
        align-self: start;
        text-align: justify;
        @include font(14px, 400, 28px);
        margin: 0;
      }
    }
  }


  #cta{
    width: 100%;
    height: 258px;
    padding: 0 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url('../../../../public/img/landing/cta-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .wrapper{
      width: 100%;
      max-width: 1300px;

      .title{
        @include font(40px, 700, 60px);
        letter-spacing: -0.5px;
      }

      .subtitle{
        @include font(16px, 400, 28px);
        letter-spacing: 0.15px;
      }

      .right{
        display: flex;
        justify-content: flex-end;
      }

      .button{
        padding: 16px 32px;
        background-color: #E92011;
        color: white;
        @include font(16px, 500);
      }
    }
  }

  @media (max-width: $phone){
    #cta{
      .wrapper{
        .title{
          @include font(28px, 700, 40px);
        }

        .right{
          justify-content: center;
        }
      }
    }
  }

  footer#contact{
    display: flex;
    justify-content: center;
    background-color: #323232;
    padding: 50px 24px;

    .wrapper{
      width: 100%;
      max-width: 1300px;
      
      .ant-typography{
        color: white;
      }
    }

    .about{
      display: grid;
        row-gap: 12px;

      &-logo{
        width: 100%;
        max-width: 450px;
      }
      
      &-description{
        @include font(16px, 400, 28px);
      }

      &-socials{
        display: flex;
        align-self: end;
        column-gap: 24px;

        img{
          width: 32px;
          height: 32px;
        }
      }
    }

    .title{
      @include font(16px, 700);
    }

    .offices{
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      &-list{
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        div{
          @include font(14px);
        }
      }
      
    }

  }
}

#overlay-navbar{
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 12px 24px;
  box-shadow: 0 10px 10px rgba(24, 24, 24, 0.1);
  background-color: white;

  opacity: 0;
  transform: translateY(-100%);
  
  .wrapper{
    width: 100%;
    max-width: 1300px;
  }

  .logo{
    width: 100%;
    max-width: 225px;
  }

  .links{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  a.link{
    @include font(16px, 500);
    color: $text-2;

  }

  .actions{
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    &-quote{
      min-width: 210px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 12px;
      background-color: $primary;
      color: white;
      @include font(14px, 500);
      
      *{
        color: white;
      }
    }

    &-menu{
      display: none;
      color: white;
      font-size: 28px;
    }
  }

  
}

@media (max-width: $laptop){
  #overlay-navbar{
    .actions-menu{
      display: flex;
    }
    .links{
      display: none;
    }
  }
}

@media (max-width: $phone){
  #overlay-navbar{
    .actions-quote{
      display: none;
    }
  }
}

#overlay-menu{
  .ant-drawer-body{
    display: flex;
    flex-direction: column;
    row-gap: 32px;   
  }

  *{
    color: #646464;
  }

  .links{
    display: flex;
    flex-direction: column;
    row-gap: 24px;    
  }

  .link{
    display: block;
    @include font(16px, 500);
  }

  .quote{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    background-color: $primary;
    color: white;
    @include font(14px, 500);
    padding: 12px 10px;
    
    *{
      color: white;
    }
  }
}