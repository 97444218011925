// Fonts
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600&display=swap');

//palette
$primary: #EE492A;

$title: #1B3138;
$text: #1B3138;
$text-2: #646464;

$white: #fff;
$gray: #323232;

// Breakpoints
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

#productos{
    background-color: $white;
    min-height: 100vh;

    *{
        font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    }

    #navbar{
        display: flex;
        justify-content: center;
        padding: 12px 24px;

        .wrapper{
            width: 100%;
            max-width: 1300px;
            margin: 0px;        
        }

        .brand{
            width: 100%;
            max-width: 263px;
        }
        .links{
            display: flex;
            justify-content: flex-start;
            column-gap: 16px;
        }


        .actions,.contact{
            display: flex;
            justify-content: flex-end;
        }

        .link{
            color: $text-2;
            font-weight: 500;
            font-size: 16px;
        }

        .button{
            display: flex;
            column-gap: 16px;
            align-items: center;
            padding: 16px 24px;
            color: $white;
            font-weight: 500;
            background-color: $primary;
            cursor: pointer;
        }

        .mobile{
            display: none;
            justify-content: flex-end;
        }

        .menu{
            padding: 12px;
            font-size: 24px;
            color: $white;
            background-color: $primary;
            border: none;
            cursor: pointer;
        }

    }

    @media (max-width: $lg){
        #navbar{
            .mobile{
                display: flex;
            }

            .links, .contact, .actions{
                display: none;
            }
        }
    }

    #main{
        display: grid;
        justify-content: center;
        grid-template-columns: 240px 880px;
        grid-template-areas: 
        'Title Title'
        'Filters List';
        gap: 32px;
        padding: 0 40px;

        .header{
            grid-area: Title;
        }

        #filters{
            grid-area: Filters;

            .form{
                display: grid;
                gap: 16px;

                .ant-input-outlined, .ant-input-outlined:hover{
                    background-color: rgba($color: $text-2, $alpha: 0.4);
                }

                .ant-card.ant-card-bordered{
                    border: 1px solid rgba($color: $text-2, $alpha: 0.5);
                    border-radius: 2px;
                }

                &-card .ant-card-body{
                    padding: 12px 10px;
                }

                &-collapse .ant-card-body{
                    padding: 0;
                }
                .ant-form-item{
                    margin: 0px;
                }

                .collapse {
                    .ant-collapse-header{
                        align-items: center;
                    }
                    .ant-collapse-header-text{
                        font-size: 16px;
                        color: $text;
                        font-weight: 500;
                    }
                }

                .radio-group{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    span.ant-radio.ant-wave-target{
                        display: none;
                    }

                    label.ant-radio-wrapper > span:not(.ant-radio.ant-wave-target){
                        color: $text-2;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    label.ant-radio-wrapper-checked > span:not(.ant-radio.ant-wave-target){
                        color: $primary;
                    }
                }
            }
        }

        .list{
            grid-area: List;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 32px;
            padding: 8px 0px;

            .item{
                width: 250px;
                border: solid 1px #dfdfdf;
                cursor: pointer;

                &:hover{
                }
                
                .ant-card-body{
                    padding: 0.5rem;
                }

                .ant-t{
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    margin: 10px 0 0 0;
                }
            }

            .spin{
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }

    @media (max-width: $xl){
        #main{
            grid-template-columns: 240px 1fr;
        }
    }
    @media (max-width: $lg){
        #main{
            display: flex;
            flex-direction: column;
            padding: 0 24px;
        }
    }

    #footer{
        display: flex;
        justify-content: center;
        background-color: $gray;
        margin-top: 100px;

        .ant-typography{
            color: $white;
            font-family: 'DM Sans', Arial, Helvetica, sans-serif;
        }

        .wrapper{
            max-width: 1300px;
            padding: 40px 24px;
        }

        .about{
            &-logo{
                width: 100%;
                max-width: 265px;
            }

            &-description{
                font-size: 16px;
                line-height: 1.8rem;
                margin: 15px 0 40px;
            }

            &-socials{
                display: flex;
                gap: 24px;
                align-items: center;
            }
        }

        .offices{
            display: flex;
            flex-direction: column;
            gap: 20px;

            &-title{
                font-size: 16px;
                font-weight: 700; 
            }

            &-list{
                display: flex;
                flex-direction: column;
                gap: 24px;
            }
        }
    }

    #overlay{
        .navbar{
            width: 100%;
            background-color: $white;
            display: flex;
            justify-content: center;
            padding: 12px 24px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 50;
            transform: translateY(-100%);
            opacity: 0;
            box-shadow: 0 10px 10px rgba(24, 24, 24, 0.1);
    
            .wrapper{
                width: 100%;
                max-width: 1300px;
                margin: 0px;        
            }
    
            .brand{
                width: 100%;
                max-width: 263px;
            }
            .links{
                display: flex;
                justify-content: flex-start;
                column-gap: 16px;
            }
    
    
            .actions,.contact{
                display: flex;
                justify-content: flex-end;
            }
    
            .link{
                color: $text-2;
                font-weight: 500;
                font-size: 16px;
            }
    
            .button{
                display: flex;
                column-gap: 16px;
                align-items: center;
                padding: 16px 24px;
                color: $white;
                font-weight: 500;
                background-color: $primary;
                cursor: pointer;
            }
    
            .mobile{
                display: none;
                justify-content: flex-end;
            }
    
            .menu{
                padding: 12px;
                font-size: 24px;
                color: $white;
                background-color: $primary;
                border: none;
                cursor: pointer;
            }
    
        }
    
        @media (max-width: $lg){
            #navbar{
                .mobile{
                    display: flex;
                }
    
                .links, .contact, .actions{
                    display: none;
                }
            }
        }
    }
}

#mobile-menu{
    .links{
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 40px;
    }

    .link{
        color: $text-2;
        font-weight: 500;
        font-size: 16px;
    }

    .button{
        display: flex;
        column-gap: 16px;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        color: $white;
        font-weight: 500;
        background-color: $primary;
    }
}