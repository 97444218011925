.categorias-content{
    min-height: 650px;

    .category-title{
        color: #EE492A
    }

    h4{
        color: #54595f
    }
}

.categorias-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}

.categorias-buttons button {
    background: #f0f0f0;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
}

.categorias-buttons button.selected {
    background: #EE492A;
    color: white;
    border-color: #EE492A;
}

/* Menú desplegable solo en móviles */
.mobile-menu {
    display: none;
    text-align: center;
    margin-bottom: 20px;
}

.mobile-dropdown button {
    margin-bottom: 5px;
}

/* Ocultar botones en móviles */
@media (max-width: 768px) {
    .categorias-buttons {
        display: none;
    }

    .mobile-menu {
        display: block;
    }
}


@media (max-width: 768px) {
    .responsive-row {
      display: flex;
      flex-direction: column;
    }
  
    .mobile-menu {
      margin-bottom: 16px;
      text-align: center;
    }
  
    .category-detail {
      padding: 16px;
    }
  
    .category-title {
      font-size: 24px;
    }
  }
  