.page-header{
    padding: 16px 24px;
    margin-left: 0px !important;
    margin-right: 0px !important;

    &-title, &-subtitle{
        margin: 0 !important;
        margin-bottom: 0 !important;
        color: white !important;
    }

    &-title{
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 32px !important;
    }

    &-back-icon{
        color: white;
    }

    .breadcrumb-header{

        ol{
            list-style: none;
            margin: 0
        }

        li{
            margin: 0 8px 0 0;
            color: white;
            font-size: 20px;
            padding: 0;
        }

        .link{
            
            text-decoration: underline;

            &:hover{
                cursor: pointer;
            }
        }

    }
}

.page-header-children:empty{
    display: none;

}