.quotations{

    &-card{
        min-height: 215px;
        height: 100%;
    }

    &-address{
        .ant-typography-edit-content{
            inset-inline-start: 0px;
            margin-top: 0;
            margin-bottom: 0;

            .ant-input{
                border: none;
            }
        }

        &-form{
            margin-top: 10px;
        }
    }

    &-files{
        &-item{
            .item-image{
                width: 86px;
                height: 86px;
            }
        }
    }
}