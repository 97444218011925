.cart{

    &-resume-text{
        font-size: 1.5rem;
        font-weight: 500;
    }
    &-resume-amount{
        font-size: 1.5rem;
        font-weight: 300;

        text-align: right;
        &:not(:empty)::before{
            content:"$ "
        }
    }
}