.ventas{

    & > .ant-row{
        margin: 0px !important;
    }

    &-collapse{
        display: flex !important;
        flex-direction: column !important;
        row-gap: 12px;
        background-color: transparent !important;
    }

    &-tab{
        border-bottom: none !important;

        & .ant-collapse-content-box{
            padding: 4px 0 16px 0 !important;
        }

        & > .ant-collapse-header{
            width: 200px;
            display: inline-flex;
            border-radius: 10px !important;

            & * {
                color: white !important;
            }
        }
        
        &.ant-collapse-item-active > .ant-collapse-header{
            border-radius: 10px 10px 0 0 !important;
        }

        &.tab-cancelados > .ant-collapse-header{
            background-color: #FF4D4F;
        }
        &.tab-pendientes > .ant-collapse-header{
            background-color: #FFE608;
        }
        &.tab-completados > .ant-collapse-header{
            background-color: #5465FF;
        }
        &.tab-rembolsados > .ant-collapse-header{
            background-color: #F2802E;
        }
    }

    &-tabname{
        color: white !important;
    }

    &-list.component-list .component-list-item{
        padding: 2px 0 !important;

        &:first-child{
            padding-top: 0px !important;
        }
    }

    &-circle{
        width: 16px;
        height: 16px;
        border-radius: 10px;

        &.small{
            width: 10px;
            height: 10px;
        }

        &.circle-1{
            background-color: #FFE608;
        }
        &.circle-2{
            background-color: #5465FF;
        }
        &.circle-3{
            background-color: #FF4D4F;
        }
        &.circle-4{
            background-color: #F2802E;
        }
    }

    &-dropdown{
        cursor: pointer !important;
    }

    &-select{
        & .ant-select-selection-item, & .ant-select-selection-item > .ant-space, & .ant-select-selection-item > .ant-space > .ant-space-item{
            line-height: 0px !important;
        }
    }
}

.venta{
    &-detalles{
        .detalle{
            &-img{
                width: 100px;
                height: 100px;
                object-fit: cover;
            }

            &-data{
                color: #686868;
                font-size: 13px;
                font-weight: 300;
                display: block;
                width: 100%;
            }

            &-name{
                font-size: 15px;
                font-weight: 500;
                display: block;
                width: 100%;
            }
        }
    }

    &-info{
        span{
            font-size: 13px;
        }

        .info{
            &-order{
                font-size: 18px;
                font-weight: 500;
            }
    
            &-date{
                span, svg{
                   opacity: 0.5; 
                }
            }
    
            &-title{
                opacity: 0.5;
                font-size: 14px;
            }
    
            &-money span{
                font-size: 30px;
                font-weight: 500;
            }
    
            &-tag{
                height: 28px;
                background-color: rgba($color: #08A0F7, $alpha: 0.2);
                display: flex;
                border-radius: 20px;
                justify-content: center;
                align-items: center;

                span{
                    color: #1778B0;
                }
            }
        }
    }
}