.collapse-productos {
    .ant-collapse-item {
        margin-bottom: 0.5rem;
        border:  none !important;

        .ant-collapse-content {
            background: #FFFFFF;
            font-family: Poppins;
            font-style: normal;
            font-size: 15px;
            vertical-align: middle;
            line-height: 50px;
            color: #FFFFFF;
            font-weight: 600;
        }
    }

    .ant-collapse-header {
        display: flex;
        background: #ee492a;
        border: 0px;
        border-radius: 8px !important;
        color: #ffffff;
    }
}

.header-collapse {
    span {
        
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF ;
    }
}


.collapse-text-big {
    
    font-weight: 500;
    font-size: 18px!important;
    line-height: 24px;
}
.collapse-text-medium{
    font-size: 15px!important;
}

.collapse-icon-big svg{
    font-size: 25px;
}